import Hero from '@components/Hero';
import Streamfields from '@streamfields/Streamfields';

import type { Streamfield, WagtailImage } from '@/types';

type Props = {
  title: string;
  banner_image: WagtailImage | null;
  content: Streamfield[];
};

function DefaultPage(props: Props) {
  const { title, banner_image: bannerImage, content } = props;

  return (
    <>
      <Hero title={title} image={bannerImage} />
      {content ? <Streamfields fields={content} /> : null}
    </>
  );
}

export default DefaultPage;
